
























































































import Vue from "vue";
import * as firebase from "firebase/auth";

import { setAuth } from "@/util/auth";

export default Vue.extend<any, any, any, any>({
  name: "Login",
  props: {
    redirectUrl: {
      type: String,
      default: "/",
    },

    ssoToken: {
      type: String,
      default: "",
    },

    roleId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    valid: false,
    ssoLogin: false,
    password: "",
    passwordRules: [(v: string) => !!v || "Password is required"],
    email: "",
    emailRules: [
      (v: string) => !!v || "E-mail is required",
      (v: string) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    submitted: false,
    isPasswordField: true,
  }),
  methods: {
    validateLogin() {
      const valid = (
        this.$refs.loginForm as Element & { validate: () => boolean }
      )?.validate();

      if (valid) {
        this.login();
      }
    },

    validateSSOLogin() {
      const ssoLogin = this.$route.query.ssoToken != null;
      if (ssoLogin) {
        this.ssoLogin = true;
        this.loginSSO();
      }
    },
    login() {
      this.submitted = true;
      this.$store.dispatch("setLoadingRequest", true);
      firebase
        .signInWithEmailAndPassword(
          firebase.getAuth(),
          this.email,
          this.password
        )
        // .then((res) => res.user.getIdToken())
        .then((res) => {
          localStorage.setItem("refresh_token", res.user.refreshToken);
          res.user.getIdToken().then((idToken) => {
            const path = (this.$route.query.redirectUrl as string) || "/";
            this.$store.dispatch("setLoadingRequest", false);
            setAuth({ token: idToken }, false);
            this.$router.push({
              path: "/select-business",
              query: { redirectUrl: path },
            });
            this.submitted = false;
          });
        })
        .catch((error: Error) => {
          this.$store.dispatch("setLoadingRequest", false);
          let message = error.message;
          if (message.includes("wrong-password")) message = "Wrong password";
          if (message.includes("user-not-found"))
            message = "User with email address not found";
          if (message.includes("too-many-requests"))
            message = "Too many attempts. Please try later";
          this.$store.dispatch("setToast", {
            title: "Login failed!",
            type: "error",
            text: message,
          });
          this.submitted = false;
        });
    },

    loginSSO() {
      this.$store.dispatch("setLoadingRequest", true);

      firebase
        .signInWithCustomToken(
          firebase.getAuth(),
          this.$route.query.ssoToken as string
        )
        .then((res) => {
          res.user.getIdToken().then((idToken) => {
            const path = (this.$route.query.redirectUrl as string) || "/";
            const roleId = this.$route.query.roleId as string;
            this.$store.dispatch("setLoadingRequest", false);
            localStorage.setItem("roleId", roleId);

            setAuth({ token: idToken, url: path });
          });
        })
        .catch((error: Error) => {
          //this.ssoLogin = false;
          this.$store.dispatch("setLoadingRequest", false);
          let message = error.message;
          if (message.includes("wrong-password")) message = "Wrong password";
          if (message.includes("user-not-found"))
            message = "User with email address not found";
          if (message.includes("too-many-requests"))
            message = "Too many attempts. Please try later";
          this.$store.dispatch("setToast", {
            title: "Login failed!",
            type: "error",
            text: message,
          });
        });
    },
  },

  mounted() {
    this.validateSSOLogin();
  },
});
